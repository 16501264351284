import React from 'react';
import { Footer } from '../footer';
import { Header } from '../header';
import { Container, Typography } from '@mui/material';
import { CookieConsent } from '../cookie-consent';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import ApiErrorNotification from '../api-error-notification/api-error-notification';

export interface LayoutProps {
  children?: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  //TODO: Move this to Contentful
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            lang
          }
        }
      }
    `,
  );
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: site.siteMetadata.lang,
        }}
        titleTemplate={
          site.siteMetadata.title ? `%s / ${site.siteMetadata.title}` : ''
        }
      />
      <Header />
      <Container>
        <Typography variant="body1" component="main">
          {children}
        </Typography>
      </Container>
      <Footer />
      <CookieConsent />
      <ApiErrorNotification />
    </>
  );
};
