import { OptionsApiListOrderInfo } from '../store/interfaces';
import {
  ApiUser,
  ApiUpdateUserFavourites,
  ApiUpdateUserCart,
  ApiOrder,
  ApiListOrderInfo,
} from './interfaces';
import { security } from './security';

const apiURL =
  process.env.API_ENDPOINT ||
  process.env.GATSBY_API_ENDPOINT ||
  'https://api-bmi.azurewebsites.net';

export const getUser = async (forEmail: string): Promise<ApiUser> => {
  const url = new URL(`${apiURL}/Users`);
  const token = await security.getAccessTokenSilently()();

  url.searchParams.append('emailAddress', forEmail);

  const options: RequestInit = {
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
    method: 'GET',
  };

  const response = await fetch(url, options);

  const data: ApiUser = await response.json();

  if (response.ok) {
    return data;
  } else {
    throw new Error('Could not get API User');
  }
};

export const updateUserFavourites = async (
  favouriteDto: ApiUpdateUserFavourites,
): Promise<ApiUser> => {
  const url = new URL(`${apiURL}/Users/FavoriteArticles`);
  const token = await security.getAccessTokenSilently()();

  const options: RequestInit = {
    method: 'PUT',
    body: JSON.stringify(favouriteDto),
    headers: new Headers({
      'Content-type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${token}`,
    }),
  };

  const response = await fetch(url, options);

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error('Could not update API Favourites');
  }
};

export const updateUserCart = async (
  cartDto: ApiUpdateUserCart,
): Promise<ApiUser> => {
  const url = new URL(`${apiURL}/Users/ShoppingCart`);
  const token = await security.getAccessTokenSilently()();

  const options: RequestInit = {
    method: 'PUT',
    body: JSON.stringify(cartDto),
    headers: new Headers({
      'Content-type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${token}`,
    }),
  };

  const response = await fetch(url, options);

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error('Could not update API Cart');
  }
};

export const sendOrder = async (order: ApiOrder): Promise<ApiOrder> => {
  const url = new URL(`${apiURL}/Orders`);
  const token = await security.getAccessTokenSilently()();

  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify(order),
    headers: new Headers({
      'Content-type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${token}`,
    }),
  };

  const response = await fetch(url, options);

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error('Could not create API Order');
  }
};

export const getOrderListInfo = async (
  requestOptions: OptionsApiListOrderInfo,
): Promise<ApiListOrderInfo> => {
  const url = new URL(
    `${apiURL}/Orders/CustomerNumber/${requestOptions.customerNumber.toString()}`,
  );

  url.searchParams.append('pageSize', requestOptions.pageSize.toString(10));
  url.searchParams.append('direction', requestOptions.direction.toUpperCase());
  url.searchParams.append('page', requestOptions.page.toString(10));
  url.searchParams.append('orderBy', requestOptions.orderBy);

  if (requestOptions.searchFilter) {
    url.searchParams.append('searchFilter', requestOptions.searchFilter);
  }

  const token = await security.getAccessTokenSilently()();

  const options: RequestInit = {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  };

  const response = await fetch(url, options);

  if (response.ok) {
    return await response.json();
  } else if (response.status === 404) {
    return {
      count: 0,
      orders: [],
      page: 0,
      pageSize: 0,
      totalCount: 0,
      totalPage: 0,
    };
  } else {
    throw new Error('Could not get API Orders');
  }
};

export const getOrder = async (orderId: number): Promise<ApiOrder> => {
  const url = new URL(`${apiURL}/Orders/${orderId.toString()}`);

  const token = await security.getAccessTokenSilently()();

  const options: RequestInit = {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  };

  const response = await fetch(url, options);

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error(`Could not get API Order: ${orderId.toString()}`);
  }
};

export const searchUser = async (): Promise<void> => {
  throw new Error('Not implemented!');
};

export const getLogs = async (): Promise<void> => {
  throw new Error('Not implemented!');
};
