import { Button, TextField, Typography } from '@mui/material';
import React, {
  FormEvent,
  MouseEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import * as styles from './search-field.module.scss';
import {
  FormState,
  useForm,
  useGetArticleList,
  useGetFramework,
  useOnClickOutside,
} from '../../hooks';
import { navigate } from 'gatsby';

interface SearchFieldProps {
  closeSearchDrawer?: () => void;
  setFocus: boolean;
}
export const SearchField = ({
  closeSearchDrawer,
  setFocus,
}: SearchFieldProps) => {
  const framework = useGetFramework();

  const initialFormState: FormState = {
    query: '',
  };
  const { onChange, values, setValues } = useForm(initialFormState);

  const articleList = useGetArticleList();
  const [searchTerms, setSearchTerms] = useState<string[]>();
  const [filteredSearchTerms, setFilteredSearchTerms] = useState<string[]>();
  const [showAutocomplete, setShowAutocomplete] = useState<boolean>(false);

  //Take articles and create a string array with search terms
  useEffect(() => {
    const reducedSearchTerms = articleList.reduce<string[]>(
      (accumulatedStrings, currentArticle) => {
        accumulatedStrings.push(currentArticle.title);
        accumulatedStrings.push(currentArticle.articleNumber);
        if (currentArticle.synonyms && currentArticle.synonyms.length > 0) {
          accumulatedStrings.push(...currentArticle.synonyms);
        }
        return accumulatedStrings;
      },
      [],
    );

    setSearchTerms(reducedSearchTerms);
  }, [articleList, setSearchTerms]);

  //Filter and sort possible matches
  useEffect(() => {
    if (values.query.length < 2) {
      setFilteredSearchTerms([]);
      setShowAutocomplete(false);
      return;
    }
    const filteredSearchTerms = searchTerms?.filter((searchTerm) => {
      return searchTerm.toLowerCase().includes(values.query.toLowerCase());
    });

    filteredSearchTerms?.sort((a, b) => {
      const aIndex = a.indexOf(values.query);
      const bIndex = b.indexOf(values.query);

      if (aIndex < bIndex) {
        return -1;
      } else if (aIndex > bIndex) {
        return 1;
      } else {
        return 0;
      }
    });

    const insertStrong = filteredSearchTerms?.map((searchTerm) => {
      const hitStringStartIndex = searchTerm.indexOf(values.query);
      const hitStringEndIndex = hitStringStartIndex + values.query.length;

      const hitStringPart = searchTerm.substring(
        hitStringStartIndex,
        hitStringStartIndex < 0 ? hitStringEndIndex + 1 : hitStringEndIndex,
      );

      return `${
        searchTerm.split(hitStringPart)[0]
      }<strong>${hitStringPart}</strong>${searchTerm.split(hitStringPart)[1]}`;
    });

    setFilteredSearchTerms(insertStrong);
    setShowAutocomplete(true);
  }, [values, searchTerms]);

  const placeholder = framework.placeholderSearch;
  const searchResultPageSlug = 'sok';

  const inputRef = useRef<null | HTMLElement>(null);
  const autocompleteRef = useRef<null | HTMLUListElement>(null);

  //Set focus to search field
  useEffect(() => {
    if (setFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [setFocus]);

  useOnClickOutside<HTMLUListElement>(autocompleteRef, () => {
    setShowAutocomplete(false);
  });

  //Handle events and keyboard navigation
  const onInputKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        moveDown(event.target);
        break;
      case 'Escape':
        event.preventDefault();
        setShowAutocomplete(false);
        break;
    }
  };

  const onListElementKeyDown = (event: KeyboardEvent<HTMLLIElement>): void => {
    switch (event.code) {
      case 'ArrowUp':
        event.preventDefault();
        moveUp(event.currentTarget);
        break;
      case 'ArrowDown':
        event.preventDefault();
        moveDown(event.currentTarget);
        break;
      case 'Enter':
      case 'NumpadEnter':
      case 'Space':
        event.preventDefault();
        doSearch(event.currentTarget.innerText);
        break;
      case 'Escape':
        inputRef?.current?.focus();
        setShowAutocomplete((previousValue) => !previousValue);
        break;
    }
  };

  const onListElementClick = (event: MouseEvent<HTMLLIElement>): void => {
    doSearch(event.currentTarget.innerText);
  };

  const moveUp = (ref: HTMLElement): void => {
    if (ref.tagName === 'LI') {
      const sibling = ref.previousElementSibling as HTMLElement;
      if (sibling) {
        sibling.focus();
      } else {
        inputRef.current?.focus();
      }
    }
  };

  const moveDown = (ref: HTMLElement): void => {
    if (ref.tagName === 'INPUT') {
      const formElement = document?.getElementById('searchForm');
      const autocompleteList = formElement?.lastElementChild;
      if (autocompleteList && autocompleteList.tagName === 'UL') {
        const listElement = autocompleteList.firstChild as HTMLElement;
        if (listElement) {
          listElement.focus();
        }
      }
    } else {
      const sibling = ref.nextElementSibling as HTMLElement;
      if (sibling && sibling.tagName === 'LI') {
        sibling.focus();
      }
    }
  };

  const doSearch = (searchTerm: string): void => {
    if (searchTerm === '') return;

    if (closeSearchDrawer) {
      closeSearchDrawer();
    }

    setValues(initialFormState);
    navigate(
      `/${searchResultPageSlug}?query=${encodeURIComponent(searchTerm)}`,
    );
  };

  const onSubmit = (event: FormEvent): void => {
    event.preventDefault();
    doSearch(values.query);
  };

  return (
    <>
      <Typography
        variant="h2"
        component="h4"
        className={styles.searchFormHeading}
      >
        {placeholder}
      </Typography>
      <form
        id="searchForm"
        className={styles.searchForm}
        onSubmit={onSubmit}
        data-testid="search-form"
      >
        <TextField
          className={styles.searchField}
          label={placeholder}
          margin="none"
          name="query"
          value={values.query}
          onChange={onChange}
          onKeyDown={onInputKeyDown}
          autoComplete="off"
          inputRef={inputRef}
        />
        <Button
          variant="contained"
          type="submit"
          className={styles.searchButton}
        >
          <SearchIcon />
        </Button>
        {showAutocomplete && (
          <ul className={styles.autocomplete} ref={autocompleteRef}>
            {filteredSearchTerms?.map((term, index) => (
              <li
                onClick={onListElementClick}
                onKeyDown={onListElementKeyDown}
                tabIndex={0}
                key={`${term.replace(' ', '-')}-${index}`}
                className={styles.autocompleteSuggestion}
                dangerouslySetInnerHTML={{ __html: term }}
              ></li>
            ))}
          </ul>
        )}
      </form>
    </>
  );
};
