import { theme } from './src/theme/theme';
import { Provider } from 'react-redux';
import { store } from './src/store';
import React from 'react';
import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from '@mui/material';
import { Auth0Provider } from '@auth0/auth0-react';
import { AppWrapper } from './src/components/app-wrapper';
import './src/theme/global.scss';

export const wrapPageElement = ({ element }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <Auth0Provider
          scope="openid profile email offline_access"
          domain={
            process.env.AUTH0_DOMAIN ||
            process.env.GATSBY_AUTH0_DOMAIN ||
            'http://www.example.com'
          }
          clientId={
            process.env.AUTH0_CLIENTID ||
            process.env.GATSBY_AUTH0_CLIENTID ||
            '1234567'
          }
          redirectUri={
            typeof window !== 'undefined' ? window.location.origin : ''
          }
          useRefreshTokens={true}
          cacheLocation="localstorage"
          audience={
            process.env.API_ENDPOINT ||
            process.env.GATSBY_API_ENDPOINT ||
            'https://api-bmi.azurewebsites.net'
          }
        >
          <AppWrapper element={element} />
        </Auth0Provider>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>
);
