import React from 'react';
import { HeaderNavigationMenu } from '../header-navigation-menu';
import { Paper } from '@mui/material';
import { HeaderServiceMenu } from '../header-service-menu';
import * as styles from './header.module.scss';

export const Header = () => {
  return (
    <Paper elevation={4} component="header" className={styles.header}>
      <HeaderServiceMenu></HeaderServiceMenu>
      <HeaderNavigationMenu></HeaderNavigationMenu>
    </Paper>
  );
};
